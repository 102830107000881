:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  /* font-weight: 300; */
  --default-spacing: 10px;
  --default-margin: 3rem;
  --medium-margin: 1rem;
  --larger-margin: 5rem;
  --primary-color: #7676d7;
  letter-spacing: 0.5px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: var(--primary-color);
  background-color: aliceblue;
}

/* common css starts */

.container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  /* max-width: 1440px; */
  padding: 10px 90px;
  /* align-items: center; */
}

.title {
  text-align: center;
  margin-top: var(--default-margin);
  color: #7676d7;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--default-margin);
  margin-top: var(--medium-margin);
  margin-bottom: var(--larger-margin);
}

.buttons .btn,
.buttons .btn:active,
.buttons .btn:focus {
  background-color: var(--primary-color);
  box-shadow: none;
  outline: none;
  border: none;
}

.error-msg {
  color: #ff0000;
  text-align: center;
}

.loading {
  color: #6565d4;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}

/* common css ends */

/* search section starts */

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--default-margin);
}

.search-section .search-input {
  min-width: 970px;
  padding: var(--default-spacing);
  margin-top: 10px;
}

.search-section .label {
  min-width: 1000px;
  padding: var(--default-spacing);
  font-size: larger;
}

.search-section .search-btn {
  margin-left: 20px;
  padding: 10px;
  cursor: pointer;
  letter-spacing: 2px;
  background-color: #6565d4;
  opacity: 0.8;
}

/* search section ends */

/* filters section starts */

.filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin: 20px 0;
}

.filters > * {
  padding: 5px 7px;
  background: #7676d7;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* filters section ends */

/* images section starts */

.images {
  margin-top: var(--medium-margin);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  /* margin: 100px; */
}

.images .image {
  width: 300px;
  height: 300px;
  justify-self: center;
  align-self: center;
  /* margin-left: 2rem; */
  border-radius: 10px;
  transition: transform 0.5s;
  /* background-color: #6565d4; */
}

.images .image-container {
  justify-self: center;
  align-self: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 25px;
  /* padding: 0 0 16px 0; */
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.image-container h4 {
  padding: 13px 0px;
}

.image-container h5 {
  padding: 13px 0px;
  margin: 0px 10px;
}

.images .image:hover {
  transform: translateY(-3px);
}

/* images section ends */

/* Responsive adjustments */
@media (max-width: 768px) {
  .images {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .search-section .search-input {
    width: 100%;
    min-width: unset;
    margin: 0 var(--default-margin);
  }

  .images {
    grid-template-columns: 1fr;
  }
}
.loader {
  /* width: 100vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
}

.source-image-container {
  margin-bottom: 40px;
  margin-top: 10px;
}

.source-image-container .source-image {
  width: 500px;
  height: 400px;
  object-fit: fill;
}

.font-black {
  color: black;
  letter-spacing: 3px;
}

.example {
  /* display: flex;
  flex-direction: column; */
  margin-left: 105px;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1); */
  /* border: 1px solid #f3f3f3; */

  /* align-items: center; */
  /* justify-content: center; */
}
